export const NavbarData = {
  logo: {
    image: 'https://www.isarchimede.edu.it/wp-content/uploads/2018/03/Logo-Cc1-1.png',
    alt: 'Logo I.S. ARCHIMEDE - Treviglio (BG)',
    url: '/'
  },
  menuData: []
}

export const HomeData = {
  title: 'ArchiTour',
  description: 'Esplora l\'I.S. ARCHIMEDE - Treviglio (BG)',
  button: 'Vai!',
  url: "https://www.architour360.it/play/index.php"
}

export const FooterData = [
  [
    'ArchiTour © %year - Tutti i diritti riservati'
  ]
]