import React from 'react';

import { NavbarData } from '../data';

const Navbar = () => {
    return(
        <nav className='app-navbar'>
            <a href={NavbarData.logo.url}>
                <img src={NavbarData.logo.image} alt={NavbarData.logo.alt} className='logo' />
            </a>
            <ul className='navbar-items'>
            {
                NavbarData.menuData.map(
                    (item, i) => {
                        return(
                            <li key={`navbar-${i}`}>
                                <a href={item.url} className='nav-links'>
                                    {item.title}
                                </a>
                            </li>
                        )
                    }
                )
            }
            </ul>
        </nav>
    )
}

export default Navbar;