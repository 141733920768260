import React from 'react';

import { FooterData } from '../data';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return(
        <footer>
            {
                FooterData.map(
                    (item, index) => <div className='footer-container' key={`footer-container-${index}`}>
                            {
                                item.map(
                                    (subItem, subIndex) => <div style={{whiteSpace: "pre-line"}} key={`footer-${subIndex}`}>{subItem.replace("%year", currentYear)}</div>
                                )
                            }
                        </div>
                )
            }
        </footer>
    )
}

export default Footer;