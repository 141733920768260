import '../styles/App.scss';
import image from '../images/arc.jpg';
import { HomeData } from '../data';

const App = () => {
	function redirect() {
		window.location.href = HomeData.url;
	}
  return (
    <div className="App">
		<header className="App-header">
			<img src={image} className="App-logo" alt="logo" />
			<h1>{HomeData.title}</h1>
			<p style={{marginTop:"-40px"}}>{HomeData.description}</p>
			<button className="button-29" onClick={redirect}>{HomeData.button}</button>
		</header>
    </div>
  );
}

export default App;
